export const columns = [
  {
    label: 'product',
    field: 'brandName',
    width: '55%',
    sortable: false,
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'passiveProductView',
    field: 'passive',
    width: '15%',
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'activeProductView',
    field: 'active',
    width: '15%',
    filterOptions: {
      enabled: false,
    },
  },
  {
    label: 'conversionProductView',
    field: 'conversion',
    width: '15%',
    filterOptions: {
      enabled: false,
    },
  },
]
